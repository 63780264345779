@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

.typography__h1 {
  @media screen and (min-width: 768px){
    font-size: $font-mobile-size-h1;
    line-height: 24px/1,2;
    font-width: 400;
  }
  @media screen and (min-width: 1024px){
    font-size: $font-desktop-size-h1;
    line-height: 60px;
    font-width: 700;
  }

}

.typography__h2{
  @media screen and (min-width: 768px){
    font-size: $font-mobile-size-h2;
    line-height: 22px/1,2;
    font-weight: 400;
  }
  @media screen and (min-width: 1024px){
    font-size: $font-desktop-size-h2;
    line-height: 36px;
    font-weight: 600;
  }

}
.typography__h3{
  @media screen and (min-width: 768px){
      font-size: $font-mobile-size-h3;
  }
  @media screen and (min-width: 1024px){
    font-size: $font-desktop-size-h3;
    line-height: 33px;
  }
  font-weight: 600;

}
.typography__h4{
  @media screen and (min-width: 768px){
    font-size: $font-mobile-size-h4;
  }
  @media screen and (min-width: 1024px) {
    font-size: $font-desktop-size-h4;
  }
  line-height: 24px;
  font-weight: 600;

}
.typography__p{
  @media screen and (min-width: 768px){
    font-size: $font-mobile-size-p;
    line-height: 20px/1.2;
  }
  @media screen and (min-width: 1024px){
    font-size: $font-desktop-size-p;
    line-height: 24px;
  }

  font-weight: 400;

}

.error-field{
  color: $theme-error-red;
  font-size: 80%;
}

.inputField__label{
  @extend .typography__p;
  font-weight: 300 !important;
}

.currencyTextAlign{
  text-align: end !important;
}
.currencyTextAlignRight{
  text-align: right !important;
}
