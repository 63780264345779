@use 'sass:color';
@import "variables";
@import "button";

label:not(.form-radio-label):not(.form-check-label):not(.label-ouside){
  padding-top: 8px;
}

/* DROP DOWN STYLE*/
.dropdown-menu{
  box-shadow: 0 0 2px $theme-light-grey;
  border: none;
  position: absolute;

  li{
    a {
      @extend .typography__p;
    }
  }
}
.dropdown-toggle::after{
  display: none;
}
.dropdown-item{
  @extend .typography__p;
}
.dropdown-item:hover{
  background-color: $theme-nb-blue;
  cursor: pointer;
}


/*INPUT STYLE*/
.input,
input:not([type='radio']){
  border-radius: $border-radius-default;

  @media screen and (min-width: 768px){
    height: 30px !important;
  }
  @media screen and (min-width: 1200px){
    height: 40px !important;
  }

  width: 100%;
  border: 0.071em solid $input-border-color;
  box-sizing: border-box;
  background-color: $input-background-color;
}

.input,
input:not([type='radio'])::placeholder {
  @media screen and (min-width: 768px){
    font-size: 80%;
  }
  @media screen and (min-width: 1200px){
    font-size: $font-sm-14;
  }
}
input:active{
  background-color: $primary-white;
}
textarea:focus,
textarea:focus-visible,
input:focus,
input:focus-visible{
    outline: none;
    border: 0.051em solid $input-border-focus !important;
}
input:focus-visible ~ .input-group-prepend > .input-group-text{
  outline: none;
  border: 0.070em solid $input-border-focus !important;
  border-left: none !important;
}

/*INPUT GROUP STYLE*/
input{
  @media screen and (min-width: 768px){
    font-size: $font-mobile-size-p;
  }
  @media screen and (min-width: 1200px){
    font-size: $font-desktop-size-p;
  }
  padding-left: $padding-default-12;
  &[readonly]{
    cursor: default;
  }
  &[disabled]{
    cursor: no-drop;
  }
}
.input-group {
  input {
    border-right: none !important;
    width: 70% !important;
    @media screen and (min-width: 768px){
      height: 30px;
      font-size: $font-mobile-size-p;
    }
    @media screen and (min-width: 1200px){
      height: 40px;
      font-size: $font-desktop-size-p;
    }
  }

  .input-group-prepend{
    @media screen and (min-width: 768px){
      height: 30px;
    }
    @media screen and (min-width: 1200px){
      height: 40px;
    }
    width: 20%;

  }

  .input-group-text {
    background-color: $input-background-color;
    border-radius: 0 $border-radius-default $border-radius-default 0;
    border: 0.071em solid #E7E7ED;
    @media screen and (min-width: 768px){
      height: 30px;
    }
    @media screen and (min-width: 1200px) {
      height: 40px;
    }

  }
  .input-group-text:active {
    background-color: $primary-white;
  }
}
.input-group-text{
  line-height: 1.94rem;
}

/*Checkbox STYLE*/
input[type=checkbox]{
  border: 1px solid $theme-light-grey !important;
}

/*NG-SELECT*/
ng-select{
  border: 0.071em $input-border-color !important;
  border-radius: $border-radius-default !important;
}
.ng-select.ng-select-single .ng-select-container{
  background-color: $input-background-color;

  @media screen and (min-width: 768px){
    height: 30px !important;
    min-height: 30px !important;
  }
  @media screen and (min-width: 1200px){
    height: 40px !important;
  }
}
.ng-select .ng-select-container{
  position: initial !important;
}
.ng-select .ng-select-container .ng-value-container .ng-input>input::placeholder{
  @media screen and (min-width: 768px){
    font-size: 80%;
  }
  @media screen and (min-width: 1200px){
    font-size: $font-sm-14;
  }
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value{
  @media screen and (min-width: 768px){
    font-size: $font-mobile-size-p;
  }
  @media screen and (min-width: 1200px){
    font-size: $font-desktop-size-p;
  }
}
.ng-select input{
    height: 25px;
    margin-top: 2px;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{
  top: 0;
}
.ng-select .ng-select-container .ng-value-container .ng-input>input{
  border: none !important;
}

ng-select.required.ng-invalid.ng-touched {
  .ng-select-container{
    border-color: #dc3545;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
  @extend .typography__p;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover{
  background-color: color.adjust($primary-base-blue, $alpha: -0.8) !important;
}

textarea{
    resize: none;
    border-radius: $border-radius-default;
    width: 100%;
    border: 0.071em solid $input-border-color;
    box-sizing: border-box;
    background-color: $input-background-color;
}

bs-datepicker-container{
    width: 278px;
}

/*FORM-CONTROL*/
.form-control:focus{
  box-shadow: none;
  border-color: $theme-light-grey;
}

.form-check-input[type=checkbox]{
  height: 18px !important;
  width: 18px !important;
  background-color: $primary-white;

  margin-top: 5px;
}
.form-check-input:checked[type=checkbox]{
  background-color: $theme-primary-dark-blue;
}
.form-check-label{
    font-weight: 600 !important;
    .form-check-input[type=checkbox] ~ &{
        padding-left: 0.5em;
    }
}
.form-radio-input[type=radio] {
    $size: 17px;
    $ring-size: 4px;

    position: absolute;
    opacity: 0;
    + .form-radio-label:before {
        width: $size;
        height: $size;
        content: '';
        background: $primary-white;
        border-radius: 100%;
        border: 1px solid darken($input-border-color, 25%);
        display: inline-block;
        position: relative;
        margin-right: 0.5em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;

        top: 4px;
        @media screen and (max-width: 1200px) {
            top: 2px;
        }

    }
    &:checked + .form-radio-label:before {
        box-shadow: inset 0 0 0 $ring-size $theme-primary-dark-blue;
        border: none;
    }
    &:focus + .form-radio-label:before {
        outline: none;
    }
    &:disabled:checked + .form-radio-label:before {
        box-shadow: inset 0 0 0 $ring-size color.adjust($theme-primary-dark-blue, $alpha: -0.4);
        border: none;
    }
    &:disabled + .form-radio-label:before {
        background: $input-background-color;
    }
    + .form-radio-label:empty:before {
        margin-right: 0;
    }
}
.form-radio-label{
    font-weight: 600 !important;
  @media screen and (min-width: 768px){
      font-size: $font-mobile-size-p;
  }
  @media screen and (min-width: 1200px) {
      font-size: $font-sm-14;
  }
}

.ng-invalid.ng-touched:not(form):not([formarrayname]):not(ng-select):not(.ng-valid):not([appdatepicker]):not(cui-input):not(div){
  border: 1px solid $theme-error-red !important;
}
input.ng-invalid.ng-touched ~ .input-group-prepend > button,
input.ng-invalid.ng-touched ~ .input-group-prepend > button{
    border: 1px solid $theme-error-red !important;
    border-left: none !important;
}
input[appdatepicker].ng-invalid.ng-touched{
    border: 1px solid $theme-error-red !important;
    border-right: none !important;
}
/*
* Angular material check box
*/
div.checkbox__material-container{
  position: relative;
}

input.checkbox__material {
  display: none;
}
.checkbox__material-container span.box {
  margin-right: 10px;
  position: relative;
  top: 2px;
}
input.checkbox__material + span.box:before,
input.checkbox__material + span.box:after {
  transition: all 0.3s;
}
input.checkbox__material + span.box:after {
  position: relative;
  left: 4px;
  top: 3px;
  margin-top: -5px;
  margin-left: -4px;
  display: inline-block;
  content: ' ';
  width: 20px;
  height: 20px;
  border: 2px solid #666;
  border-radius: 2px;
  background-color: #fff;
  z-index: 1000;
}
input.checkbox__material:checked + span.box:after {
  background-color: #2196f3;
  border-color: #2196f3;
}
input.checkbox__material:checked + span.box:before {
  transform: rotate(45deg);
  position: absolute;
  left: 7px;
  top: 0;
  width: 6px;
  height: 13px;
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  border-color: #fff;
  content: '';
  z-index: 10000;
}
