@import "variables";
.bs-datepicker {
  border-radius: 3px;
  .bs-datepicker-container {
    padding: 0;
  }

  .bs-datepicker-head {
    background-color: $theme-primary-dark-blue !important;
  }

  .bs-datepicker-body table td span.selected {
    background-color: $theme-primary-dark-blue !important;
  }

  .bs-datepicker-body table td.week span {
    color: $theme-primary-dark-blue !important;
    font-size: $font-desktop-size-p !important;
  }

  .bs-datepicker-body table.days span {
    font-size: $font-desktop-size-p;
  }
  .bs-datepicker-body table th{
    color: $primary-black;
  }
  .bs-datepicker-body table td{
    color: $primary-black;
  }
}
