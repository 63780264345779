@import "variables";

.display__end{
  float: right;
}
.tab-content>.active{
  margin-top: $margin-default-12;
}

.margin__right{
  margin-right: $margin-default-12;
}
.row{
  @media screen and (min-width: 768px){
    padding: 8px;
  }

  @media screen and (min-width: 1200px){
    padding: $padding-default-12;
  }

}

.ml-1 {
  margin-left: ($spacer * .25) !important;
}

.m-top-12{
  margin-top: $margin-default-12;
}
.m-bottom-12{
  margin-bottom: $margin-default-12;
}
.m-left-12{
  margin-left: $margin-default-12;
}
.m-left-8{
  margin-left: $margin-8;
}
.m-right-12{
  margin-right: $margin-default-12;
}


.p-top-12{
  padding-top: $padding-default-12
}

.p-sm-top-12 {
  @include media-breakpoint-up(sm) {
    padding-top: $padding-default-12
  }
}

.p-bottom-12{
  padding-bottom: $padding-default-12
}
.p-left-12{
  padding-left: $padding-default-12
}
.p-right-12{
  padding-right: $padding-default-12
}
.bottom_layout_container{
  position: relative;
}
.bottom_layout_container_position{
  position: absolute;
  bottom: 0;
  left: 0;
}
.m-left-align{
  margin-left: 0 !important;
}
.left-align{
  padding-left: 0 !important;
}
.m-right-align{
  margin-right: 0 !important;
}
.right-align{
  padding-right: 0 !important;
}
.top-align{
  padding-top: 0 !important;
}
.searchField_alignment{
  @media screen and (max-width: 1200px){
    padding-top: 4px !important;
  }
}
.text-right{
  text-align: right !important;
}
.gap-md{
  gap: 0.9rem;
}

.full-width{
  width: 100%;
}
