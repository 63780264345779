@import "variables";

table {
    border-collapse: separate !important;
    border-spacing: 0;
}

.table__bordered_style {
    border: solid #ccc 1px;
    border-radius: $border-radius-default;

    .table__bordered_style>:not(caption)>*{
        border-width: 0;
    }
}


.table__bordered_style tr:hover {
    background-color: $tr-focus-color;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}
.table__bordered_style td, .table__bordered_style th {
    border-left: none;
    border-top: 1px solid #ccc;
    padding: $padding-default-12;
    text-align: left;
}
.table__bordered_style th {
    background-color: #3C3C46;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#F8F8F8), to(#ECECEC));
    background-image: -webkit-linear-gradient(to bottom, #F8F8F8, #ECECEC);
    background-image: -moz-linear-gradient(to bottom, #F8F8F8, #ECECEC);
    background-image: linear-gradient(to bottom, #F8F8F8, #ECECEC);
    border-top: none;
    @extend .typography__p;
    font-weight: 600 !important;
}

.table__bordered_style td {
    @extend .typography__p ;

}


.table__bordered_style td:last-child td:first-child {
    border: none;
}

.table__bordered_style th:first-child {
    border-radius: $border-radius-default 0 0 0;
}
.table__bordered_style th:last-child {
    border-radius: 0 $border-radius-default 0 0;
}
.table__bordered_style th:only-child{
    border-radius: $border-radius-default $border-radius-default 0 0;
}
.table__bordered_style tr:last-child td:first-child {
    border-radius: 0 0 0 $border-radius-default;
}
.table__bordered_style tr:last-child td:last-child {
    border-radius: 0 0 $border-radius-default 0;
}
.table__bordered_style tr:last-child{
    border: 1px transparent;
}


.table_container__scrollable{
    overflow: auto;
    border-radius: $border-radius-default;
    max-height: 400px;
    @media screen and (min-width: 768px) and (max-width: 960px){
        max-height: 200px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1500px){
        max-height: 400px;
    }
    
    table{
        width: 100%;
    }
    thead th{
        position: sticky;
        top: 0;
        border-top: 1px solid #ccc;
    }
    tbody th{
        position: sticky;
        left: 0;
    }
}

// needs to be used where the table isn't the only thing on the modal
// and any higher might cause it to be cropped
.table_container__scrollable__small{
    max-height: 200px !important;
}

.table-scrollable-md {
    max-height: 300px !important;
    overflow-y: scroll;
}