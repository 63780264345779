@import "variables";
@import "nbui/colors";

.accordion-button:not(.collapsed) {
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-preview {
  --bs-accordion-bg: #{$monotone-100};
}

.accordion-preview .collapse:not(.show) {
  display: block;
}

.accordion-preview .collapse:not(.show) .accordion-body {
  max-height: 118px;
  position: relative;
  overflow: hidden;
}

.accordion-preview .collapse:not(.show) .accordion-body:after {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: linear-gradient(to top,
      rgba(251, 251, 251, 1) 10%,
      rgba(251, 251, 251, 0) 60%);
  pointer-events: none;
}

.accordion-preview .accordion-button:not(.collapsed) {
  --bs-accordion-bg: #fbfbfb;
  --bs-accordion-active-bg: #fbfbfb;
  --bs-accordion-btn-focus-border-color: #fbfbfb;
  box-shadow: none;
}

.accordion-max-width {
  max-width: 820px;
}

.accordion-alt-btn {
  --bs-accordion-btn-icon: url('../../../assets/images/accordian-down.svg');
  --bs-accordion-btn-active-icon: url('../../../assets/images/accordian-down.svg');
  --bs-accordion-btn-icon-width: 2.5rem;
}

.accordion-alt-btn-small {
  --bs-accordion-btn-icon: url('../../../assets/images/accordian-down.svg');
  --bs-accordion-btn-active-icon: url('../../../assets/images/accordian-down.svg');
  --bs-accordion-btn-icon-width: 24px;
}

.accordion-alt-btn-small .accordion-button:not(.collapsed){
  background-color: $monotone-100;
  box-shadow: none !important;
}

.accordion-alt-btn-small-text {
  color: $monotone-10;
}
