
@mixin minWidth( $point ) {
  @if $point==240 {
    @media (min-width: 240px) {
      @content;
    }
  }
  @if $point==320 {
    @media (min-width: 320px) {
      @content;
    }
  }
  @if $point==480 {
    @media (min-width: 480px) {
      @content;
    }
  }
  @if $point==600 {
    @media (min-width: 600px) {
      @content;
    }
  }
  @if $point==768 {
    @media (min-width: 768px) {
      @content;
    }
  }
  @if $point==960 {
    @media (min-width: 960px) {
      @content;
    }
  }
  @if $point==1024 {
    @media (min-width: 1200px) {
      @content;
    }
  }
  @if $point==1140 {
    @media (min-width: 1140px) {
      @content;
    }
  }
  @if $point==1280 {
    @media (min-width: 1280px) {
      @content;
    }
  }
  @if $point==1500 {
    @media (min-width: 1500px) {
      @content;
    }
  }
}
@mixin maxWidth( $point ) {
  @if $point==240 {
    @media (max-width: 240px) {
      @content;
    }
  }
  @if $point==320 {
    @media (max-width: 320px) {
      @content;
    }
  }
  @if $point==480 {
    @media (max-width: 480px) {
      @content;
    }
  }
  @if $point==600 {
    @media (max-width: 600px) {
      @content;
    }
  }
  @if $point==768 {
    @media (max-width: 768px) {
      @content;
    }
  }
  @if $point==960 {
    @media (max-width: 960px) {
      @content;
    }
  }
  @if $point==1024 {
    @media (max-width: 1200px) {
      @content;
    }
  }
  @if $point==1140 {
    @media (max-width: 1140px) {
      @content;
    }
  }
  @if $point==1280 {
    @media (max-width: 1280px) {
      @content;
    }
  }
  @if $point==1500 {
    @media (max-width: 1500px) {
      @content;
    }
  }
}

// target specific browsers
// http://aslanbakan.com/en/blog/browser-and-device-specific-css-styles-with-sass-and-less-mixins/
// -----------------------------------

@mixin browser($browsers: Mozilla) {
  @each $browser in $browsers {
    html[data-browser*="#{$browser}"] & {
      @content;
    }
  }
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}