@import "src/app/styles/scss/nbui/colors";
@import "src/app/styles/scss/nbui/variables";
@import "src/app/styles/scss/nbui/utilities";

@import "src/app/styles/scss/variables";
@import "bootstrap";
@import "styles/bs-theme.scss";

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "src/app/styles/scss/accordion";
@import "src/app/styles/scss/button";
@import "src/app/styles/scss/datepicker";
@import "src/app/styles/scss/formControl";
@import "src/app/styles/scss/layout";
@import "src/app/styles/scss/mixins";
@import "src/app/styles/scss/modal";
@import "src/app/styles/scss/switch";
@import "src/app/styles/scss/tables";
@import "src/app/styles/scss/tooltip";
@import "src/app/styles/scss/typography";
@import "src/app/styles/scss/icons";

$tooltip-max-width: 200px !default;
$tooltip-max-height: 200px !default;
$spacer: 1rem !default;
$grey: #eee !default;
$border-radius: .25rem !default;

$tooltip-padding-y: $spacer * .25 !default;
$tooltip-padding-x: $spacer * .5 !default;
$tooltip-color: $primary-black !default;
$tooltip-bg: $primary-white !default;
$tooltip-border-radius: $border-radius !default;

.nb-toast-success {
  background-color: #F1F1F1;
  border: solid 1px #47C464;
  border-radius: 4px;
  width: 28em!important;
  color: #287D3C!important;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00Ljg3Ni4yMkMxLjQxNyAxLjE4Mi0uNjg3IDQuNzEyLjIwNSA4LjA1N2MuNjE3IDIuMzE4IDIuMTggMy45NDEgNC40NzcgNC42NSAyLjMwMi43MTEgNC42Ni4xMTEgNi40LTEuNjI4IDEuODYyLTEuODYyIDIuNDQ4LTQuODE3IDEuNDA1LTcuMDg3bC0uMzY0LS43OTMtMy4xNDQgMy4wNjgtMy4xNDUgMy4wNjgtMS41MzYtMS41My0xLjUzNy0xLjUzLjQzOS0uNDU3LjQzOC0uNDU4IDEuMTEgMS4xMDEgMS4xMSAxLjEwMkw4LjU4IDQuODRsMi43MjItMi43MjItLjM2OC0uMzkyQzEwLjA4Mi44MTggNy44MzItLjAyOCA2LjM1IDBBNy4xNjYgNy4xNjYgMCAwIDAgNC44NzYuMjJ6IiBmaWxsPSIjMjk3RDNEIi8+PC9zdmc+);

  .toast-close-button {
    color: #287D3C!important;
    font-size: 28px;
    font-weight: 400;
    right: -0.1em;
    top: -0.5em;
  }
}

.tap-n-pay-button-override {
  margin-left: 0px !important;
  border-radius: 8px !important;
}

.tap-n-pay-btn-action-override {
  margin: 0px !important;
}

* {
  font-family: 'Open Sans', sans-serif;
  // Wrapper for the tooltip content
  .tooltip-inner {
    max-width: $tooltip-max-width;
    max-height: $tooltip-max-height;
    padding: $tooltip-padding-y $tooltip-padding-x;
    color: $tooltip-color;
    text-align: center;
    background-color: $tooltip-bg;
    overflow: hidden;
  }
}

body {
  background-color: #FEFCF9;
}

// CUI Overrides
cui-input {
  label {
    font-size: 13px !important;
  }
}