/*FONTS DESKTOP*/
$font-desktop-size-h1: 52px;
$font-desktop-size-h2: 26px;
$font-desktop-size-h3: 24px;
$font-desktop-size-h4: 18px;
$font-desktop-size-p: 16px;

/*FONTS MOBILE*/
$font-mobile-size-h1: 18px;
$font-mobile-size-h2: 16px;
$font-mobile-size-h3: 14px;
$font-mobile-size-h4: 14px;
$font-mobile-size-p: 12px;
$font-mobile-icon: 12px;

$accordion-body-padding-x: 14px;
$accordion-button-padding-x: 14px;

$accordion-icon-width: 1.5rem;

/*FONT BUTTON*/
$font-btn-regular: 16px;
$font-sm-14: 14px;

/*FONT COLOR*/
$font-link-inactive-color: #090909;
$font-color-03: #ffffff;

/*PRIMARY COLOR*/
$primary-base-blue: #00A3D8;
$primary-white: #FFF;
$primary-dark-blue: #2d4a61;
$primary-black: #000;
$primary-off-blue: #E4F1FC;
$primary-off-green: #BFDECC;
$primary-dark-red: #AB2930;
$primary-active-green: #CAE9D9;
$primary-active-blue: #E4F1FC;
$primary-active-red: #F3CFD1;
$primary-light-green: #BFDECC;
$primary-disabled: #EAEAEA;
$primary-disabled-button: #EAEAEA;
$primary-disabled-button-text: #EAEAEA;


/*THEME PRIMARY*/
$theme-nb-blue: #00A3D8;
$theme-primary-dark-blue: #103783;

/*THEME SECONDARY*/
$theme-secondary-blue: $theme-nb-blue;


/*LIGHT THEME*/
$theme-modal-white: #FFFCF9;
$theme-light-off-white: #FEFCF9;
$theme-light-white: #F3F4F5;
$theme-light-dark-grey: #777E80;
$theme-light-grey: #a6adaf;


/*THEME SUCCESS*/
$theme-success-green: #34B96F;
$theme-success-green-dark: #008843;
$theme-success-green-label: #229053;


/*THEME ERROR*/
$theme-error-red: $primary-dark-red;

/*MARGIN*/
$margin-default-12: 12px;
$margin-xsmall-3: 3px;
$margin-medium-18: 18px;
$margin-8: 8px;
$margin-16: 16px;
$margin-large-24: 24px;
$margin-40: 40px;
$margin-64: 64px;
$margin-80: 80px;

/*PADDING-INTERNAL*/
$padding-default-12: 12px;
$padding-xsmall-3: 3px;
$padding-medium-18: 18px;
$padding-large-24: 24px;
$padding-xlarge-40: 40px;

/*INPUT*/
$input-border-color: #E7E7ED;
$input-background-color: #fffcf9;
$input-border-focus: #80ACFF;

/*TABLE*/
$tr-focus-color: #F0F5FF;

/*NAVBAR*/
$navbar-grey: #F2F2F2;

/*BUTTON-COLOR*/
$button-tertiary-color: #5C71DE;
$navlink-active: #52A0D6;

$border-radius-default: 0.250em; //3px;
$border-radius-round: 50%;


/*BOOKING-STATUS*/

$booking-status-waiting: $theme-nb-blue;
$booking-status-confirmed: $theme-primary-dark-blue;
$booking-status-paid: $theme-success-green-label;
$booking-status-reserved: #AB2930;
$booking-status-unavailable: #A6ACAF;
$booking-status-provisional: #FF8D86;
$booking-status-outstanding: #D77644;

/*NAVIGATION-BAR*/
$navlink-height: 80px;
