@import "variables";
/* Needed to show tooltips on table rows */
$tooltip-background: $primary-white;
$tooltip-color: $primary-black;
.tooltip__container {
  .tooltip-inner {
    background: $tooltip-background;
    width: max-content;
    color: $tooltip-color;
    font-size: 1rem;
    border: 1px solid $theme-nb-blue;
    @media screen and (min-width: 768px){
      font-size: $font-mobile-size-p;
    }
    @media screen and (min-width: 1200px){
      font-size: $font-mobile-size-h4;
    }

  }
  .arrow::before {
    border-top-color: $tooltip-background;
  }
  &.left {
    .tooltip-inner {
      text-align: left;
      white-space: pre-wrap;
    }
  }

  z-index: 9999;
  overflow: visible;
  position: relative;
}

.tooltip__primary .tooltip-inner{
  background-color: $primary-aqua-95 ;
  color: $primary-navy-05 ;
}

.tooltip.show{
    opacity: 1;
}
.tooltip-arrow {
  display: none !important;
}