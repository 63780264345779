@import "./colors";

$primary: $primary-navy-05;
$secondary: $primary-navy-99;
$danger: $danger-400;

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 900px,
  mdl: 1024px, //md on tablet landscape
  lg: 1200px,
  xl: 1536px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1390px
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2.3,
  6: $spacer * 2.8,
  12: $spacer * 0.75,
  20: $spacer * 1.25,
  200: $spacer * 2,
  sm: $spacer * 0.875,
  xs: $spacer * 0.375,
  xxs: $spacer * 0.188,
);

$negative-spacers: map-merge(
  $spacers,
  (
    n1: -$spacer * .25,
    n2: -$spacer * .5,
    n3: -$spacer * .625,
    n4: -$spacer * 0.875,
    n5: -$spacer * 1.5,
    n6: -$spacer * 2.3,
    n7: -$spacer * 2.8,
    n12: -$spacer * 0.75,
    n20: -$spacer * 1.25,
    n200: -$spacer * 2,
    nsm: -$spacer * 0.875,
    nxs: -$spacer * 0.375,
    nxxs: -$spacer * 0.188,
  )
);



$font-weight-semibold: 600;
$font-size-base: 1rem;
$font-size-sm: $font-size-base * .875 !default;
$font-size-lg: $font-size-base * 1.25 !default;
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  12: $font-size-base * 0.75,
  14: $font-size-base * 0.875,
  16: $font-size-base * 1,
  18: $font-size-base * 1.125,
);


/*
* Component bootstrap variables
*/

/* Accordion */
$accordion-button-focus-box-shadow: none;
$accordion-button-padding-y: 10px;
$accordion-button-padding-x: 12px;
$accordion-body-padding-y: 0px;
$accordion-body-padding-x: 12px;
$accordion-bg: $monotone-100;

/* Input */
$border-radius-15: 15px;
$input-border-radius: 8px;
$input-border-color: $monotone-50;

$input-btn-padding-y: .375rem;
$input-btn-padding-x: .75rem;
$input-btn-padding-y-sm: .25rem;
$input-btn-padding-x-sm: .5rem;
$input-btn-padding-y-lg: .5rem;
$input-btn-padding-x-lg: 1rem;

$input-btn-font-size: $font-size-base;
$input-btn-font-size-sm: $font-size-sm;
$input-btn-font-size-lg: $font-size-lg;

$input-padding-y: 1rem;
$input-padding-x: $input-btn-padding-x;
$input-padding-y-sm: 1rem;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-padding-y-lg: 1rem;
$input-padding-x-lg: $input-btn-padding-x-lg;

/* Button */
$btn-font-size: $input-btn-font-size;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-font-size-lg: $input-btn-font-size-lg;

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;

/* Form Control */
$form-floating-padding-x: 12px;
$form-floating-padding-y: 10px;
$form-floating-line-height: 1rem;

$form-floating-line-height: 1.25;
$form-floating-padding-x: 0.5rem;
$form-floating-padding-y: 1rem;
$form-floating-padding-x-sm: 0.25rem;
$form-floating-padding-y-sm: 1rem;
$form-floating-padding-x-lg: 0.75rem;
$form-floating-padding-y-lg: 1rem;
$form-floating-input-padding-t: 1.625rem;
$form-floating-input-padding-b: .625rem;
$form-floating-input-padding-t-sm: 0.75rem;
$form-floating-input-padding-b-sm: .38rem;
$form-floating-input-padding-t-lg: 1rem;
$form-floating-input-padding-b-lg: .38rem;
$form-floating-label-opacity: .65;
$form-floating-label-transform: scale(.85) translateY(-.5rem) translateX(.15rem);
$form-floating-transition: opacity .1s ease-in-out, transform .1s ease-in-out;

/* Table */
$table-striped-bg: $monotone-90;
$table-bg: $monotone-80;
$table-border-color: transparent;

/* Card */
$card-border-radius: 8px;
$card-border-color: $monotone-50;
$card-cap-bg: $monotone-100;

/* Date picker */
$main-bg: $monotone-100 !default;
$custom-range-bg: #eee !default;

$main-box-shadow: #aaa !default;

$border-color: $secondary-200 !default;
$highlighted: $secondary-200 !default;

$btn-bg: #e9edf0 !default;
$btn-bg-hover: #d5dadd !default;

$btn-bg2: #9aaec1 !default;
$btn-bg2-hover: #54708b !default;
