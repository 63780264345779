@import "nbui/utilities";
@import "bootstrap/scss/mixins";

.modal{
    border-radius: 3px;
}
.modal-padding {
  padding-top: $margin-large-24;
  padding-bottom: $margin-large-24;
}
.modal-dialog {
  @media(min-width: 576px) {
    max-width: 90%;
  }
  //
  //  @media screen and (min-width: 768px) and (max-width: 960px){
  //  }
  //  @media screen and (min-width: 1200px) and (max-width: 1140px){
  //  }
}

//  width: fit-content;
//  overflow: hidden;
//  position: absolute;
//  top: 0;
//}
.modal-dialog-centered{
  margin: 0 auto;
  align-items: baseline;
  justify-content: center;
}
.modal-content {
  background-color: $theme-modal-white;
  width: inherit;
  border-radius: 3px;
  box-shadow: 0 2px 16px 2px rgba(222,222,222,0.5);

}
.modal.fade-anim {
  transition: opacity 400ms ease-in-out;
  will-change: opacity;
  opacity: 0;
}
.modal.fade-anim.in {
  opacity: 1;
}
.modal-open {
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    padding-right: 0!important;
  }
}

.modal-footer,
.modal-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 4em; //56px;
  padding: 0 $padding-medium-18;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.modal-header {
  border-bottom: none;
  justify-content: space-between;
  display: flex;
  margin: 12px 0 12px 0;
}
.modal-body {
  padding: $padding-large-24;
  background-color: $primary-white;
  box-shadow: 0 2px 16px 2px rgba(222,222,222,0.5);
  border-radius: $border-radius-default;

  @media screen and (min-width: 768px){
    margin: 0 $margin-default-12 $margin-8 $margin-default-12;
  }

  @media screen and (min-width: 1024px){
    margin: 0 $margin-large-24 $margin-16 $margin-large-24;
  }

  @media screen and (max-width: 1200px){
    padding: $padding-default-12;
  }
}
.modal-footer {
  border-top: none;
  width: 100%;
  height: 15%;
  padding: $padding-default-12;
}

.close-modal{
  width: 40px;
  text-align: center;
  .helper{
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
    img{
      vertical-align: middle;
        height: 16px;
        width: 16px;
    }
}
.close-modal:hover{
  cursor: pointer;
}
.modal-backdrop-overlay{
  z-index: 1055 !important;
}
.modal-window-centered .modal-dialog-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  min-height: initial !important;
}

.modal-decorator {
  &.confirmation {
    max-width: 450px !important;

    .btn-close:focus {
      box-shadow: none;
    }
  }
  .modal-footer .btn-primary {
    min-width: 120px;
  }

  .modal-content {
    @include media-breakpoint-up(sm) {
      border-radius: 8px;
    }
  }
}

.model-rounded {
  .modal-content {
    border-radius: 8px;
  }
}

@media (max-width: map-get($grid-breakpoints, mdl)) {
  .modal-dialog.modal-fullscreen-mdl-down {
    background-color: red;
    width: 100vw;
    height: 100vh;
    max-width: none;
    margin: 0;
  }

  .modal-dialog.modal-fullscreen-mdl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
}